import { styled } from '@mui/material'

export const BlogContent = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  m: '0 auto',
  mb: theme.spacings.sm,
  '& h2': {
    ...theme.typography.h4,
    mb: '5px',
  },
  '& p': {
    mb: theme.spacings.sm,
  },
  '& blockquote': {
    ...theme.typography.h1,
    color: theme.palette.primary.main,
    textAlign: 'center',
    p: `0 ${theme.spacings.xl}`,
    quotes: `"‘" "’" "“" "”" "‘" "’" "“" "”"`,

    '&::before': {
      content: 'open-quote',
    },
    '&::after': {
      content: 'close-quote',
    },
  },
}))
