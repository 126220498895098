import { GoogleAnalyticsItemList } from '@graphcommerce/googleanalytics'
import { AddProductsToCartForm, ProductItemsGridProps } from '@graphcommerce/magento-product'
import { PromotionBlockListFragment } from '../Blog/PromotionBlockList.gql'
import { CallADruggistQuery } from '../Dialogs/MoreInformationDialog/CallADruggist.gql'
import { ProductListItemsBase } from './ProductListItemsBase'
import { productListRenderer } from './productListRenderer'

type PromotionItems = PromotionBlockListFragment['blocks'] | null | undefined
type PromotionBlockProps = { promotionItems: PromotionItems }

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'> &
  CallADruggistQuery & { categoryId?: string }

export function ProductListItems(props: ProductListItemsProps & PromotionBlockProps) {
  const { callADruggist, infoForm } = props

  return (
    <AddProductsToCartForm>
      <GoogleAnalyticsItemList {...props}>
        <ProductListItemsBase
          renderers={productListRenderer({ callADruggist, infoForm })}
          {...props}
        />
      </GoogleAnalyticsItemList>
    </AddProductsToCartForm>
  )
}
