import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { Box } from '@mui/material'
import { BlogContent } from './BlogContent/BlogContent'
import { RowBlogContentFragment } from './RowBlogContent.gql'

type RowBlogContentProps = RowBlogContentFragment

export function RowBlogContent(props: RowBlogContentProps) {
  const { content } = props
  if (!content) return null
  return (
    <BlogContent>
      <RichText
        {...content}
        renderers={{
          image: ({ src, width, height, title, mimeType }) => (
            <Box sx={{ width, maxWidth: '100%' }}>
              <Asset asset={{ url: src, alt: title, width, height, mimeType }} />
            </Box>
          ),
        }}
      />
    </BlogContent>
  )
}
