import { RenderType, TypeRenderer } from '@graphcommerce/next-ui'
import { SxProps, Theme } from '@mui/material'
import { RowBlogContent } from '../Blog'
import { PageContentQueryFragment } from './PageContentQueryFragment.gql'
import { RowBanner } from './RowBanner/RowBanner'
import { RowBannerThree } from './RowBannerThree/RowBannerThree'
import { RowButtonLinkList } from './RowButtonLinkList/RowButtonLinkList'
import { RowColumnOne } from './RowColumnOne/RowColumnOne'
import { RowColumnThree } from './RowColumnThree/RowColumnThree'
import { RowColumnTwo } from './RowColumnTwo/RowColumnTwo'
import { RowContentLinks } from './RowContentLinks/RowContentLinks'
import { RowFormsApp } from './RowFormsApp/RowFormsApp'
import { RowLinks } from './RowLinks/RowLinks'
import { RowProduct } from './RowProduct/RowProduct'
import { RowQuote } from './RowQuote/RowQuote'
import { RowRecentBlogposts } from './RowRecentBlogposts/RowRecentBlogposts'
import { RowRendererFragment } from './RowRenderer.gql'
import { RowServiceOptions } from './RowServiceOptions/RowServiceOptions'
import { RowSpecialBanner } from './RowSpecialBanner/RowSpecialBanner'

type ContentTypeRenderer = TypeRenderer<PageContentQueryFragment['pages'][0]['content'][0]>

const defaultRenderer: Partial<ContentTypeRenderer> = {
  RowColumnOne,
  RowColumnTwo,
  RowColumnThree,
  RowSpecialBanner,
  RowQuote,
  RowBlogContent,
  RowButtonLinkList,
  RowServiceOptions,
  RowContentLinks,
  RowProduct,
  RowLinks,
  RowBannerThree,
  RowRecentBlogposts,
  RowBanner,
  RowFormsApp,
}

export type PageProps = RowRendererFragment & {
  renderer?: Partial<ContentTypeRenderer>
  sx?: SxProps<Theme>
}

export function RowRenderer(props: PageProps) {
  const { content, renderer, sx } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as ContentTypeRenderer
  return (
    <>
      {content?.map((item) => (
        <RenderType key={item.id} renderer={mergedRenderer} {...item} sx={sx} />
      ))}
    </>
  )
}
