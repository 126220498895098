import React from 'react'
import { RowContentLinksFragment } from './RowContentLinks.gql'
import { Inline, ItemSlider, ImageLink } from './variant'

type VariantRenderer = Record<
  NonNullable<RowContentLinksFragment['contentLinksVariant']>,
  React.VFC<RowContentLinksFragment>
>

type RowContentLinksProps = RowContentLinksFragment & { renderer?: Partial<VariantRenderer> }

const defaultRenderer: Partial<VariantRenderer> = {
  Inline,
  ItemSlider,
  ImageLink,
}

/** @deprecated Replace with RowLinks */
export function RowContentLinks(props: RowContentLinksProps) {
  const { renderer, contentLinksVariant, ...RowContentLinksProps } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer

  if (!contentLinksVariant) return null

  const RenderType =
    mergedRenderer?.[contentLinksVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production')
        return <>renderer for {contentLinksVariant} not found</>
      return null
    })

  return <RenderType contentLinksVariant={contentLinksVariant} {...RowContentLinksProps} />
}
