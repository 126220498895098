import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { RichText } from '@graphcommerce/graphcms-ui'
import { ProductListItemsFragment, AddProductsToCartForm } from '@graphcommerce/magento-product'
import {
  iconChevronLeft,
  iconChevronRight,
  RenderType,
  Row,
  SidebarSliderProps,
  IconSvg,
} from '@graphcommerce/next-ui'
import { Box, Typography, Button } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { RowProductFragment } from '../RowProduct.gql'

type SwipeableProps = RowProductFragment & ProductListItemsFragment & Pick<SidebarSliderProps, 'sx'>

export function Swipeable(props: SwipeableProps) {
  const { title, productCopy, pageLinks, items, sx } = props

  if (!items || items.length === 0) return null

  return (
    <AddProductsToCartForm>
      <Row
        maxWidth={false}
        disableGutters
        sx={[{ mb: 'auto', height: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ScrollerProvider scrollSnapAlign='start'>
          <Box
            sx={(theme) => ({
              height: '100%',
              display: 'grid',
              gridTemplateColumns: `200px 1fr`,
              maxWidth: '100%',
              gap: theme.spacings.sm,
              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'none',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                alignContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: '1fr auto',
                  alignItems: 'center',
                },
              })}
            >
              {productCopy?.raw && productCopy?.raw?.children[0]?.children[0]?.text !== '' ? (
                <RichText
                  {...productCopy}
                  sxRenderer={{
                    paragraph: (theme) => ({
                      typography: 'h4',
                      fontWeight: theme.typography.fontWeightLight,
                      [theme.breakpoints.up('sm')]: {
                        textAlign: 'right',
                      },
                      [theme.breakpoints.down('sm')]: {
                        lineHeight: '1em',
                        pr: theme.spacings.xs,
                      },
                    }),
                  }}
                />
              ) : (
                <Typography
                  variant='h4'
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightLight,
                    [theme.breakpoints.down('sm')]: {
                      pr: theme.spacings.xs,
                    },
                    [theme.breakpoints.up('sm')]: {
                      textAlign: 'right',
                    },
                  })}
                >
                  {title}
                </Typography>
              )}

              {pageLinks.map((pageLink, index) => (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${pageLink.title}_${index}`}
                  href={pageLink.url}
                  variant='cutRight'
                  size='small'
                  color='primary'
                  sx={(theme) => ({
                    maxWidth: '155px',
                    justifySelf: 'flex-end',
                    [theme.breakpoints.down('sm')]: {
                      whiteSpace: 'nowrap',
                      maxWidth: 'none',
                    },
                  })}
                >
                  <Typography
                    variant='inherit'
                    component='div'
                    dangerouslySetInnerHTML={{
                      __html: pageLink.title.replace('[[', '<span>').replace(']]', '</span>'),
                    }}
                  />
                </Button>
              ))}
            </Box>

            <Box sx={{ position: 'relative', minWidth: 1 }}>
              <Scroller
                hideScrollbar
                sx={(theme) => ({
                  height: '100%',
                  gap: theme.spacings.xs,
                  pr: theme.page.horizontal,
                  gridAutoColumns: 200,
                })}
              >
                {items?.map((item) =>
                  item ? (
                    <RenderType
                      key={item.uid ?? ''}
                      renderer={productListRenderer()}
                      sizes='200px'
                      {...item}
                    />
                  ) : null,
                )}
              </Scroller>
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  gridAutoFlow: 'row',
                  gap: theme.spacings.xxs,
                  position: 'absolute',
                  left: theme.spacings.sm,
                  top: `calc(50% - 28px)`,
                })}
              >
                <ScrollerButton direction='left' sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <IconSvg src={iconChevronLeft} />
                </ScrollerButton>
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  gap: theme.spacings.xxs,
                  position: 'absolute',
                  right: theme.spacings.sm,
                  top: `calc(50% - 28px)`,
                })}
              >
                <ScrollerButton direction='right'>
                  <IconSvg src={iconChevronRight} />
                </ScrollerButton>
              </Box>
            </Box>
          </Box>
        </ScrollerProvider>
      </Row>
    </AddProductsToCartForm>
  )
}
