import {
  ProductListItemFragment,
  ProductListItemProps,
  ProductListItemRenderer,
} from '@graphcommerce/magento-product'
import { RenderType, breakpointVal, responsiveVal } from '@graphcommerce/next-ui'
import { Box, BoxProps, Typography } from '@mui/material'
import { useMemo } from 'react'
import { DefaultPageQuery } from '../../graphql/DefaultPage.gql'
import { PromotionBlock } from '../Blog/PromotionBlock'

type PromotionItems =
  | NonNullable<NonNullable<DefaultPageQuery['pages'][0]>['modular']>['components'][0]['blocks']
  | null
  | undefined

export type ProductItemsGridProps = {
  items?:
    | Array<(ProductListItemFragment & ProductListItemProps) | null | undefined>
    | null
    | undefined
  renderers: ProductListItemRenderer
  loadingEager?: number
  title: string
  size?: 'normal' | 'small'
  sx?: BoxProps['sx']
  promotionItems: PromotionItems
} & Pick<ProductListItemProps, 'onClick' | 'titleComponent'>

export function ProductListItemsBase(props: ProductItemsGridProps) {
  const {
    items,
    sx = [],
    renderers,
    promotionItems,
    loadingEager = 0,
    size = 'normal',
    titleComponent,
    onClick,
  } = props

  const list = useMemo(() => {
    const mergedList: (
      | NonNullable<PromotionItems>[0]
      | NonNullable<ProductItemsGridProps['items']>[0]
    )[] = Object.assign([], items)

    promotionItems?.map((item) => mergedList.splice(item.index, 0, item))
    return mergedList
  }, [items, promotionItems])

  return (
    <Box
      sx={[
        (theme) => ({
          display: 'grid',
          gridColumnGap: theme.spacings.md,
          gridRowGap: theme.spacings.md,
        }),
        size === 'small' && {
          gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 280)}, 1fr))`,
        },
        size === 'normal' && {
          gridTemplateColumns: { xs: `repeat(2, 1fr)`, md: `repeat(3, 1fr)`, lg: `repeat(4, 1fr)` },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {list?.map((item, idx) => {
        if (item?.__typename === 'PromotionBlock') {
          return (
            <Typography
              sx={(theme) => ({
                '& p': { ...breakpointVal('fontSize', 20, 25, theme.breakpoints.values) },
              })}
            >
              <PromotionBlock promotionItem={item} />
            </Typography>
          )
        }
        if (item) {
          return (
            <RenderType
              key={item.uid ?? ''}
              renderer={renderers}
              sizes={
                size === 'small'
                  ? { 0: '100vw', 354: '50vw', 675: '30vw', 1255: '23vw', 1500: '337px' }
                  : { 0: '100vw', 367: '48vw', 994: '30vw', 1590: '23vw', 1920: '443px' }
              }
              {...item}
              loading={loadingEager > idx ? 'eager' : 'lazy'}
              titleComponent={titleComponent}
              onClick={onClick}
              noReport
            />
          )
        }
        return null
      })}
    </Box>
  )
}
