import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { Sizes } from '@graphcommerce/image'
import { iconChevronLeft, iconChevronRight, responsiveVal, IconSvg } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'
import { useState } from 'react'
import { Banner } from '../Banner/Banner'
import { BannerFragment } from '../Banner/Banner.gql'
import { AutoScroll } from './AutoScroll'

export type Props = {
  banners: BannerFragment[]
  sizes?: Sizes
  sx?: SxProps<Theme>
  autoScroll?: boolean
}

export function BannerSlider(props: Props) {
  const { banners, sizes, sx = [], autoScroll } = props

  const [isHover, setIsHover] = useState(false)

  return (
    <Box
      sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <ScrollerProvider>
        <Scroller
          hideScrollbar
          sx={(theme) => ({
            height: '100%',
            gridAutoColumns: '100%',
            background: theme.palette.background.paper,
          })}
        >
          {banners.map((slide, index) => (
            <Banner
              key={slide.id}
              type='main'
              banner={slide}
              loading={index === 0 ? 'eager' : 'lazy'}
              sizes={sizes}
            />
          ))}
        </Scroller>
        <Box sx={{ position: 'absolute', left: 0, top: `calc(50% - 62px)` }}>
          <ScrollerButton
            direction='left'
            sx={(theme) => ({
              display: { xs: 'flex' },
              position: 'relative',
              width: '44px',
              height: '88px',
              borderRadius: '0 44px 44px 0px',
              boxShadow: 'none',
              transform: 'none',
              [theme.breakpoints.down('sm')]: {
                width: '24px',
                height: '48px',
                borderRadius: '0 24px 24px 0px',
              },
            })}
          >
            <IconSvg
              src={iconChevronLeft}
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette.primary.main,
                fontSize: responsiveVal(20, 62),
                [theme.breakpoints.down('md')]: {
                  strokeWidth: 2,
                },
              })}
            />
          </ScrollerButton>
        </Box>
        <Box sx={{ position: 'absolute', right: 0, top: `calc(50% - 62px)` }}>
          <ScrollerButton
            direction='right'
            sx={(theme) => ({
              display: { xs: 'flex' },
              position: 'relative',
              width: '44px',
              height: '88px',
              borderRadius: '44px 0 0 44px',
              boxShadow: 'none',
              transform: 'none',
              [theme.breakpoints.down('sm')]: {
                width: '24px',
                height: '48px',
                borderRadius: '24px 0 0 24px',
              },
            })}
          >
            <IconSvg
              src={iconChevronRight}
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette.primary.main,
                fontSize: responsiveVal(20, 62),
                [theme.breakpoints.down('md')]: {
                  strokeWidth: 2,
                },
              })}
            />
          </ScrollerButton>
        </Box>
        {autoScroll && <AutoScroll pause={isHover} />}
      </ScrollerProvider>
    </Box>
  )
}
