import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { breakpointVal, Button, ImageTextBoxed, responsiveVal } from '@graphcommerce/next-ui'
import { Typography, useTheme } from '@mui/material'
import { RowSpecialBannerFragment } from './RowSpecialBanner.gql'

type RowSpecialBannerProps = RowSpecialBannerFragment

export function RowSpecialBanner(props: RowSpecialBannerProps) {
  const { copy, asset, topic, pageLinks } = props

  const theme = useTheme()

  return (
    <ImageTextBoxed
      sx={{
        marginBottom: theme.spacings.sm,
        '&.ImageTextBoxed-root': {
          px: 0,
        },
        '& .ImageTextBoxed-wrapper': {
          backgroundColor: 'primary.light',
          border: 0,
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: 0,
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 3,
            theme.shape.borderRadius * 7,
            theme.breakpoints.values,
          ),
        },
        '& .ImageTextBoxed-copy': {
          maxWidth: '80%',
        },
        '& .ImageTextBoxed-asset': {
          width: '100%',
        },
      }}
      item={
        asset?.url && (
          <Image
            alt={asset.alt ?? ''}
            width={1532}
            height={1678}
            src={asset.url}
            sizes={{
              0: '100vw',
              [theme.breakpoints.values.md]: responsiveVal(100, 600),
            }}
          />
        )
      }
    >
      {topic && (
        <Typography variant='overline' color='textSecondary'>
          {topic}
        </Typography>
      )}
      {copy?.raw && (
        <RichText
          {...copy}
          sxRenderer={{
            'bulleted-list': {
              mb: theme.spacings.sm,
              pl: 0,
              '& li': {
                ...theme.typography.body2,
                display: 'flex',
                alignItems: 'center',
                listStyleType: 'none',
                pl: 0,
                py: 0.2,
              },
              '& li::before': {
                display: 'inline-block',
                content: '""',
                width: 18,
                height: 18,
                backgroundImage: `url(/check-green.svg#icon)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                mr: theme.spacings.xxs,
              },
            },
            link: {
              textDecoration: 'underline',
              color: 'inherit',
              '&:hover, &:focus, &:active': {
                color: 'primary.main',
              },
            },
          }}
          withMargin
        />
      )}

      {pageLinks?.length > 0 && (
        <Button
          href={pageLinks[0].url}
          key={pageLinks[0].url}
          variant='pill'
          color='info'
          size='large'
          sx={{
            ...theme.typography.body1,
            backgroundColor: 'background.paper',
            paddingY: '10px',
            paddingX: theme.spacings.sm,
          }}
        >
          {pageLinks[0].title}
        </Button>
      )}
    </ImageTextBoxed>
  )
}
