import { Asset } from '@graphcommerce/graphcms-ui'
import { Box, Typography } from '@mui/material'
import { PromotionBlockListFragment } from './PromotionBlockList.gql'

type PromotionItem = PromotionBlockListFragment['blocks'][0] | null | undefined
type PromotionBlockProps = { promotionItem: PromotionItem }

export function PromotionBlock(props: PromotionBlockProps) {
  const { promotionItem } = props
  const asset = promotionItem?.image

  return (
    <Box
      sx={(theme) => ({
        background: '#FACDCE',
        height: '100%',
        borderRadius: '10px',
        p: theme.spacings.xs,
        display: 'flex',
        overflow: 'hidden',
      })}
    >
      <Box
        sx={{
          textAlign: 'center',
          m: 'auto',
          minWidth: 'min-content',
          width: { xs: '100%', lg: '90%' },
        }}
      >
        {asset ? <Asset sx={{ maxWidth: { xs: '80px', md: '100px' } }} asset={asset} /> : null}
        <Typography
          sx={(theme) => ({
            '& a': {
              color: theme.palette.text.primary,
            },
            '& p': {
              fontWeight: theme.typography.fontWeightRegular,
              m: 0,
              lineHeight: '1.3em',
            },
          })}
          variant='h4'
          dangerouslySetInnerHTML={{ __html: promotionItem?.content?.html ?? '' }}
        />
      </Box>
    </Box>
  )
}
