import { Scroller, ScrollerProvider, ScrollerButton } from '@graphcommerce/framer-scroller'
import { Image } from '@graphcommerce/image'
import { iconChevronLeft, iconChevronRight, IconSvg } from '@graphcommerce/next-ui'
import { Container, Link, Typography, darken, Box, styled } from '@mui/material'
import { m } from 'framer-motion'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

type InlineProps = RowContentLinksFragment

const MotionDiv = styled(m.div)({})

export function ItemSlider(props: InlineProps) {
  const { title, contentLinks } = props

  return (
    <Container
      maxWidth='lg'
      sx={(theme) => ({
        py: theme.spacings.lg,
        [theme.breakpoints.down('md')]: { pr: 0 },
      })}
    >
      <Typography sx={(theme) => ({ mb: theme.spacings.md })} variant='h3' align='center'>
        {title}
      </Typography>
      <ScrollerProvider scrollSnapAlign='end'>
        <Box sx={{ position: 'relative', minWidth: 1 }}>
          <Scroller
            hideScrollbar
            sx={(theme) => ({
              gridAutoColumns: '1fr',
              gap: theme.spacings.xs,
            })}
          >
            {contentLinks.map((pageLink) => (
              <Link key={pageLink.id} href={pageLink.url} underline='none' color='inherit'>
                <Box
                  sx={(theme) => ({
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.light,
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    py: theme.spacings.xs,
                    px: theme.spacings.xxs,
                    minWidth: 200,
                    transition: theme.transitions.create(['background-color'], {
                      duration: theme.transitions.duration.short,
                    }),
                  })}
                >
                  {pageLink?.asset && (
                    <Image
                      src={pageLink.asset.url}
                      layout='fill'
                      unoptimized
                      sx={{ height: '75px', widht: 'auto' }}
                    />
                  )}
                  <Typography
                    component='span'
                    variant='body1'
                    align='center'
                    sx={(theme) => ({
                      mt: theme.spacings.xxs,
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    {pageLink.title}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Scroller>
          <MotionDiv
            layout
            sx={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              margin: 'auto',
              zIndex: '2',
              display: 'inline-table',
            }}
          >
            <ScrollerButton
              direction='left'
              sx={(theme) => ({
                width: 42,
                height: 42,
                background: theme.palette.background.paper,
                '&:hover': {
                  background: darken(theme.palette.background.paper, 0.2),
                },
              })}
            >
              <IconSvg src={iconChevronLeft} />
            </ScrollerButton>
          </MotionDiv>
          <MotionDiv
            layout
            sx={{
              position: 'absolute',
              right: '-21px',
              top: '0',
              bottom: '0',
              margin: 'auto',
              zIndex: '2',
              display: 'inline-table',
            }}
          >
            <ScrollerButton direction='right'>
              <IconSvg src={iconChevronRight} />
            </ScrollerButton>
          </MotionDiv>
        </Box>
      </ScrollerProvider>
    </Container>
  )
}
