import { Asset, RichText, RichTextProps } from '@graphcommerce/graphcms-ui'
import { ColumnOne } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import type { RowColumnOneFragment } from './RowColumnOne.gql'

export type RowColumnOneProps = RowColumnOneFragment & {
  richTextOne?: Omit<RichTextProps, 'raw'>
}

export function RowColumnOne(props: RowColumnOneProps) {
  const { colOne, richTextOne } = props

  return (
    <ColumnOne maxWidth='lg'>
      <RichText
        {...colOne}
        {...richTextOne}
        sxRenderer={{
          iframe: {
            aspectRatio: { xs: `9 / 16`, md: `16 / 11` },
          },
        }}
        renderers={{
          image: ({ src, width, height, title, mimeType }) => (
            <Box sx={{ width, maxWidth: '100%' }}>
              <Asset asset={{ url: src, alt: title, width, height, mimeType }} />
            </Box>
          ),
        }}
      />
    </ColumnOne>
  )
}
