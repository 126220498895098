import { ContentLinks } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

type InlineProps = RowContentLinksFragment

export function Inline(props: InlineProps) {
  const { contentLinks, title } = props

  return (
    <ContentLinks title={title}>
      {contentLinks.map((contentLink) => (
        <Link href={contentLink.url} variant='body1' color='inherit' underline='hover'>
          {contentLink.title}
        </Link>
      ))}
    </ContentLinks>
  )
}
