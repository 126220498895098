import { Row } from '@graphcommerce/next-ui'
import { Box, Container } from '@mui/material'
import { Banner } from '../../Banner/Banner'
import { RowBannerFragment } from './RowBanner.gql'

export function RowBanner(props: RowBannerFragment) {
  const { rowBannerContent } = props

  return (
    <Row
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.contrastText,
        m: 0,
        py: theme.spacings.lg,
        '&.MuiContainer-root': {
          maxWidth: 'none',
        },
      })}
    >
      <Container
        sx={{
          '&.MuiContainer-root': {
            maxWidth: '1160px',
            px: '0px',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            gap: theme.spacings.md,

            '& .copy': {
              flexWrap: 'wrap',
              flexDirection: 'row',
            },

            '& .copy p': {
              ...theme.typography.h5,
              flexBasis: '50%',
              flexGrow: 1,
              flexShrink: 1,
              fontSize: theme.typography.body2,
              lineHeight: '23px',
            },

            '& .copy h1, & .copy h2, & .copy h3,& .copy h4, & .copy h5,& .copy h6': {
              ...theme.typography.h4,
              lineHeight: '23px',
              flexBasis: '100%',
              mb: 0,
            },

            '& img': {
              maxHeight: '280px',
              pt: theme.spacings.sm,
              boxSizing: 'content-box',
              objectFit: 'contain',
            },

            '& .MuiButton-root': {
              maxWidth: '140px',
            },
          })}
        >
          {rowBannerContent?.map((banner) => (
            <Banner banner={banner} key={banner.id} type='main' sx={{ p: '0!important' }} />
          ))}
        </Box>
      </Container>
    </Row>
  )
}
