import { Asset } from '@graphcommerce/graphcms-ui'
import { Link } from '@mui/material'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

type ImageLinkProps = RowContentLinksFragment

export function ImageLink(props: ImageLinkProps) {
  const { contentLinks } = props

  return (
    <>
      {contentLinks.map((pageLink) => (
        <Link key={pageLink.id} href={pageLink.url} underline='none' color='inherit'>
          {pageLink?.asset && (
            <Asset asset={pageLink.asset} sx={{ maxWidth: pageLink.asset.width }} />
          )}
        </Link>
      ))}
    </>
  )
}
